import React from 'react';

import LoadingContentPlaceholder from '../components/LoadingContentPlaceholder';
import ProfileServiceProvider from './ProfileServiceProvider';
import { useTranslation } from 'react-i18next';
import ProfileClient from './ProfileClient';
import Sidebar from '../components/Sidebar';
import { CONST } from '../constants/Const';
import useAuth from '../hooks/useAuth';
import SEO from '../components/SEO';
import '../styles/App.css';

function EditProfile() {
  
  const { t, i18n } = useTranslation();
  const lng = i18n.language.split('-')[0];
  const { userType, isLoading } = useAuth();

  return (
    <div className="d-flex">

      <SEO
        title={t('SEO.EditProfile.PageTitle')}
        description={t('SEO.EditProfile.PageDescription')}
        keywords={t('SEO.EditProfile.Keywords')}
        url={`${window.location.origin}/${lng}`}
        image={`${window.location.origin}/images/taskimap.jpg`}
      />

      <Sidebar />
      
      <div className="mb-5 mt-2 flex-grow-1">
        {isLoading ? (
          <LoadingContentPlaceholder />
        ) : userType === CONST.PROFESSIONAL ? (
          <ProfileServiceProvider />
        ) : userType === CONST.CLIENT ? (
          <ProfileClient />
        ) : (
          <div>{t('EditProfile.ProfileNotAvailable')}</div>
        )}
      </div>
    </div>
  );
}

export default EditProfile;