import React, { useState } from 'react';

import RegisterClientForm from '../components/RegisterClientForm';
import { useUser } from '../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Col } from 'react-bootstrap';
import SEO from '../components/SEO';
import '../styles/App.css';

function RegisterUser() {

  const { t, i18n } = useTranslation();
  
  const storedLocation = JSON.parse(localStorage.getItem('geoData'));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const lng = i18n.language.split('-')[0];
  const { registerClient } = useUser();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    password_confirmation: ''
  });


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await registerClient(formData);
      navigate(`/${i18n.language.split('-')[0]}/dashboard`);

    } catch (err) {
      console.log({err});
      setError(err.response?.data?.message || t('Errors.userRegistrationError'));

    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="vh-100 d-flex flex-column justify-content-between">
      
      <SEO
        title={t('SEO.RegisterUser.PageTitle')}
        description={t('SEO.RegisterUser.PageDescription')}
        keywords={t('SEO.RegisterUser.Keywords')}
        url={`${window.location.origin}/${lng}`}
        image={`${window.location.origin}/images/taskimap.jpg`}
      />

      <Header 
        showTitle={true} 
        showRegisterButtom={false}
        loadingLocation={false}
        currentLocation={storedLocation.location}
      />

      <div className="register-container">
        <Col md={6} className="mx-auto">
          <RegisterClientForm 
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            loading={loading}
            error={error}
            onCancel={() => navigate(`/${i18n.language.split('-')[0]}`)}
          />
        </Col>
      </div>

      <Footer />

    </div>
  );
}

export default RegisterUser;

