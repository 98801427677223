// TwoFactorAuthModal.js

import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import { requestTwoFactor } from '../services/TaskiMapApiService';
import { useTranslation } from 'react-i18next';
import { FaShieldAlt } from 'react-icons/fa';

const TwoFactorAuthModal = ({
  show,
  onHide,
  onConfirm,
  description,
  purpose,
  tempToken,
  error,
  setError,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [method, setMethod] = useState('email'); // 'email' o 'sms'
  const [codeRequested, setCodeRequested] = useState(false);
  const firstInputRef = useRef(null);

  // Función para mapear códigos de error a mensajes de usuario
  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'invalid_or_expired_code':
        return t('Errors.invalidOrExpiredCode');
      case 'two_factor_authentication_is_disabled':
        return t('Errors.twoFactorDisabled');
      // Agrega más casos según sea necesario
      default:
        return t('Errors.genericError');
    }
  };

  const handleRequestCode = async () => {
    setLoading(true);
    setError(null);
    try {
      // Solicitar el código al API
      await requestTwoFactor({ two_factor_type: method, purpose }, tempToken);
      setCodeRequested(true);
    } catch (err) {
      const errorMessage = err.response?.data?.message;
      setError(t('Errors.' + errorMessage));
    } finally {
      setLoading(false);
    }
  };

  const handleCodeChange = (e, index) => {
    const val = e.target.value.replace(/[^0-9]/g, ''); // Solo dígitos

    if (!val) {
      // Si la entrada está vacía, limpiar el código en este índice
      const newCode = code.split('');
      newCode[index] = '';
      setCode(newCode.join(''));
      return;
    }

    const newCode = code.split('');

    if (val.length === 1) {
      // Entrada de un solo dígito
      newCode[index] = val;
      setCode(newCode.join(''));

      // Mover el foco al siguiente campo
      if (e.target.nextSibling && e.target.nextSibling.tagName === 'INPUT') {
        e.target.nextSibling.focus();
      }
    } else {
      // Entrada de múltiples dígitos (datos pegados)
      // Distribuir la entrada entre los campos a partir del índice actual
      for (let i = index; i < index + val.length && i < 6; i++) {
        newCode[i] = val[i - index];
      }
      setCode(newCode.join(''));

      // Actualizar los valores de los inputs
      const inputs = e.target.parentNode.querySelectorAll('input');
      inputs.forEach((input, idx) => {
        input.value = newCode[idx] || '';
      });

      // Enfocar el último campo rellenado
      const lastFilledIndex = index + val.length - 1;
      if (inputs[lastFilledIndex]) {
        inputs[lastFilledIndex].focus();
      } else {
        // Si excede el número de campos, enfocar el último
        inputs[inputs.length - 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      const newCode = code.split('');
      newCode[index] = '';
      setCode(newCode.join(''));
      // Mover al campo anterior si no estamos en el primero
      if (index > 0) {
        if (e.target.previousSibling && e.target.previousSibling.tagName === 'INPUT') {
          e.target.previousSibling.focus();
        }
      }
    } else if (e.key >= '0' && e.key <= '9') {
      // Permitimos dígitos
    } else if (e.ctrlKey || e.metaKey) {
      // Permitimos combinaciones con Ctrl o Command (como Ctrl+V o Command+V)
    } else {
      e.preventDefault(); // Prevenimos otras teclas
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      // Ejecutar la acción pendiente con el código
      await onConfirm(code, method);
    } catch (err) {
      const errorCode = err.response?.data?.message;
      const errorMessage = getErrorMessage(errorCode);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (codeRequested && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [codeRequested]);

  const handleHide = () => {
    setError(null);
    onHide();
  };

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FaShieldAlt className="me-2" />
          {t('TwoFactorAuthModal.Title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {description && <p>{description}</p>}
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          {!codeRequested && (
            <>
              <Form.Group controlId="twoFactorMethod">
                <Form.Label>{t('TwoFactorAuthModal.SelectMethod')}</Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    label={t('TwoFactorAuthModal.Email')}
                    name="twoFactorMethod"
                    value="email"
                    checked={method === 'email'}
                    onChange={(e) => {
                      setMethod(e.target.value);
                      setError(null);
                    }}
                  />
                  <Form.Check
                    type="radio"
                    label={t('TwoFactorAuthModal.SMS')}
                    name="twoFactorMethod"
                    value="sms"
                    checked={method === 'sms'}
                    onChange={(e) => {
                      setMethod(e.target.value);
                      setError(null);
                    }}
                  />
                </div>
              </Form.Group>
              <Button
                variant="primary"
                onClick={handleRequestCode}
                disabled={loading}
                className="mt-3 w-100"
              >
                {loading ? <Spinner animation="border" size="sm" /> : t('TwoFactorAuthModal.RequestCode')}
              </Button>
            </>
          )}

          {codeRequested && (
            <>
              <Form.Group controlId="twoFactorCode">
                <Form.Label>{t('TwoFactorAuthModal.CodeLabel')}:</Form.Label>
                <div className="d-flex justify-content-center">
                  {[...Array(6)].map((_, index) => (
                    <Form.Control
                      key={index}
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      autoComplete="off"
                      className="text-center mx-1"
                      style={{ width: '40px', fontSize: '24px' }}
                      value={code[index] || ''}
                      onChange={(e) => handleCodeChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      ref={index === 0 ? firstInputRef : null}
                    />
                  ))}
                </div>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                className="mt-3 w-100"
                disabled={loading || code.length < 6}
              >
                {loading ? <Spinner animation="border" size="sm" /> : t('TwoFactorAuthModal.Confirm')}
              </Button>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default TwoFactorAuthModal;