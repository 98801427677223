import React, { useState } from 'react';

import LoginForm from '../components/LoginForm'; // Importa el componente LoginForm
import { useUser } from '../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Col } from 'react-bootstrap';
import SEO from '../components/SEO';
import '../styles/App.css';

function Login() {

  const { t, i18n } = useTranslation();
  
  const storedLocation = JSON.parse(localStorage.getItem('geoData'));
  const lng = i18n.language.split('-')[0];
  const navigate = useNavigate();
  
  return (
    <div className="vh-100 d-flex flex-column justify-content-between">
      
      <SEO
        title={t('SEO.Login.PageTitle')}
        description={t('SEO.Login.PageDescription')}
        keywords={t('SEO.Login.Keywords')}
        url={`${window.location.origin}/${lng}`}
        image={`${window.location.origin}/images/taskimap.jpg`}
      />

      <Header 
        showTitle={true} 
        showLoginButtom={false}
        loadingLocation={false}
        currentLocation={storedLocation?.location || ''}
      />

      <div className="login-container">
        <Col md={3} className="mx-auto">
          {/* Aquí integramos el componente LoginForm */}
          <LoginForm onClose={() => navigate('/')} callBackAction={() => navigate(`/${i18n.language.split('-')[0]}/dashboard`)} />
        </Col>
      </div>

      <Footer />

    </div>
  );
}

export default Login;