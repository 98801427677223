// TwoFactorConfigModal.js

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import TwoFactorAuthModal from './TwoFactorAuthModal';
import { enableTwoFactor, disableTwoFactor } from '../services/TaskiMapApiService';
import { useTranslation } from 'react-i18next';

const TwoFactorConfigModal = ({ show, onHide, twoFactorEnabled, setTwoFactorEnabled }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleEnableTwoFactor = async (code) => {
    setLoading(true);
    setError(null);
    try {
      // Llamar al endpoint para habilitar 2FA con el código
      await enableTwoFactor({ code });
      setTwoFactorEnabled(true);
      setShowAuthModal(false);
      onHide();
    } catch (err) {
      setError(err.response?.data?.message || t('Errors.enableTwoFactorFailed'));
    } finally {
      setLoading(false);
    }
  };

  const handleDisableTwoFactor = async () => {
    setLoading(true);
    setError(null);
    try {
      // Llamar al endpoint para deshabilitar 2FA
      await disableTwoFactor();
      setTwoFactorEnabled(false);
      onHide();
    } catch (err) {
      setError(err.response?.data?.message || t('Errors.disableTwoFactorFailed'));
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = () => {
    if (!twoFactorEnabled) {
      setShowAuthModal(true); // Mostrar el modal para solicitar y validar el código
    } else {
      handleDisableTwoFactor(); // Deshabilitar 2FA directamente
    }
  };

  useEffect(() => {
    if (show) {
      setError(null);
    }
  }, [show]);

  const handleHide = () => {
    setError(null);
    onHide();
  };

  return (
    <>
      <Modal show={show} onHide={handleHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('TwoFactorConfigModal.Title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group controlId="enableTwoFactor" className="mt-3">
              <Form.Check
                type="checkbox"
                label={t('TwoFactorConfigModal.Enable')}
                checked={twoFactorEnabled}
                onChange={handleCheckboxChange}
                disabled={loading}
              />
            </Form.Group>
          </Form>
          {loading && <Spinner animation="border" className="mt-3" />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {t('TwoFactorConfigModal.Close')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para solicitar y validar el código de 2FA */}
      <TwoFactorAuthModal
        show={showAuthModal}
        onHide={() => setShowAuthModal(false)}
        onConfirm={handleEnableTwoFactor} // Pasar el código a enableTwoFactor
        description={t('TwoFactorConfigModal.EnableDescription')}
        purpose="settings"
        error={error}
        setError={setError}
      />
    </>
  );
};

export default TwoFactorConfigModal;