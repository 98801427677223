import React, { useEffect, useState } from 'react';
import { fetchLegalDocument } from '../services/TaskiMapApiService';
import CookieConsent from '../components/CookieConsent';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Spinner } from 'react-bootstrap';
import SEO from '../components/SEO';

const PrivacyPolicy = () => {

  const { t, i18n } = useTranslation();
  const lng = i18n.language.split('-')[0];
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadDocument = async () => {
      try {
        const response = await fetchLegalDocument('privacy_policy', i18n.language);
        const formattedContent = response.data.data.content.replace(/\r?\n/g, '<br />');
        setContent(formattedContent);
      } catch (error) {
        console.error('Error fetching privacy policy:', error);
      } finally {
        setLoading(false);
      }
    };

    loadDocument();
  }, [i18n.language]);

  return (
    <>
      <SEO
        title={t('SEO.PrivacyPolicy.PageTitle')}
        description={t('SEO.PrivacyPolicy.PageDescription')}
        keywords={t('SEO.PrivacyPolicy.Keywords')}
        url={`${window.location.origin}/${lng}`}
        image={`${window.location.origin}/images/taskimap.jpg`}
      />
      
      <div className="vh-100 d-flex flex-column justify-content-between">

        <div className="legal-document-container">
          <h1>{t('Footer.PrivacyPolicy')}</h1>

          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </div>

        <Footer />
      </div>

      <CookieConsent />
    </>
  );
};

export default PrivacyPolicy;