import React, { useEffect, useState, useRef } from 'react';

import { fetchUserProfile, deleteServiceFile, fetchCategories, fetchRateTypes, saveService } from '../services/TaskiMapApiService';
import { Modal, Button, Form, Row, Col, Tooltip, OverlayTrigger, Container, Table, Alert  } from 'react-bootstrap';
import { MdOutlineAddPhotoAlternate, MdAdd, MdDelete, MdVisibility } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import CustomTextarea from './CustomTextarea';
import CustomSelect from './CustomSelect';
import CustomField from './CustomField';
import MapPicker from './MapPicker';

// Crea el componente de detalle de servicio
function ServiceDetail({ title, data, show, onHide, onSave }) {

  const { t } = useTranslation();

  const [center, setCenter] = useState({ lat: 40.712776, lng: -74.005974 });
  const [maxBodyHeight, setMaxBodyHeight] = useState('400px');
  const [extendedPrice, setExtendedPrice] = useState(0);
  const [categories, setCategories] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [rateTypes, setRateTypes] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [radius, setRadius] = useState(100);
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');
  const [files, setFiles] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [area, setArea] = useState(0);
  const fileInputRef = useRef(null);

  
  // Setting up initial form state based on data prop
  const [serviceInfo, setServiceInfo] = useState({
    name: '',
    description: '',
    category_id: '',
    unit_price: '',
    rate_type_id: '',
    taxable: '',
  });


  // Obtener las coordenadas de la ciudad que el usuario ha seleccionado en su perfil
  async function fetchCoordinates(city) {
    const response = await fetch(`https://nominatim.openstreetmap.org/search?city=${encodeURIComponent(city)}&format=json&limit=1`);
    const data = await response.json();
    if (data.length > 0) {
      return { lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lon) };
    }
    return null;
  }


  // Cargar la informacion del servicio en caso de edición o limpiar el formulario en caso de creación
  useEffect(() => {
    async function initialize() {
      if (data) {
        setImages(data.files || []);
        setServiceInfo({
          name: data.name || '',
          description: data.description || '',
          category_id: data.category_id || '',
          unit_price: data.unit_price || '',
          rate_type_id: data.rate_type_id || '',
          taxable: data.taxable.toString() || '0',
        });

        // Set tax and schedule data
        setTaxes(data.taxes || []);
        setSchedules(data.schedules || []);

        if(data.latitude && data.longitude) {
          // Establece el centro y el radio del mapa a partir de los datos recibidos
          const lat = parseFloat(data.latitude);
          const lng = parseFloat(data.longitude);
          const operationArea = parseInt(data.operation_area);

          setCenter({ lat, lng });
          setRadius(operationArea);
          setArea(Math.PI * (operationArea * operationArea) / 1e6); // Ajustar el área basada en el radio

        } else {
          // Obtener las coordenadas de la ciudad
          const response = await fetchUserProfile();
          const dataUser = response.data.data.user;
          const coordinates = await fetchCoordinates(dataUser.data_user.city);
          if (coordinates) {
            setCenter(coordinates);
          } else {
            console.error("No se pudo obtener las coordenadas para la ciudad especificada.");
            // Establece un centro por defecto si no se pueden cargar las coordenadas
            setCenter({ lat: 40.712776, lng: -74.005974 }); // Nueva York como fallback
          }
        }

      } else {

        setImages([]);
        setServiceInfo({
          name: '',
          description: '',
          category_id: '',
          unit_price: '',
          rate_type_id: '',
          taxable: '',
        });

        // Set tax and schedule data
        setTaxes([]);
        setSchedules([]);

        const response = await fetchUserProfile();
        const dataUser = response.data.data.user;
        const coordinates = await fetchCoordinates(dataUser.data_user.city);

        if (coordinates) {
          setCenter(coordinates);
        } else {
          console.error("No se pudo obtener las coordenadas para la ciudad especificada.");
          // Establece un centro por defecto si no se pueden cargar las coordenadas
          setCenter({ lat: 40.712776, lng: -74.005974 }); // Nueva York como fallback
        }

        // Establecer el radio y el área
        setRadius(1000);
        setArea(100);
      }
    }

    initialize();
  }, [data]);


  const handleAddImageClick = () => {
    fileInputRef.current.click();
  };


  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const newImagesWithPreview = newFiles.map(file => {
      const previewUrl = URL.createObjectURL(file);
      return { file, url: previewUrl, isPreview: true, loaded: false };
    });

    setFiles([...files, ...newFiles]);
    setImages([...images, ...newImagesWithPreview]);
  };


  const handleImageDisplay = () => {
    return images.map((img, index) => (
      <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '10px', marginBottom: '10px' }}>
        <div style={{ width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img
            src={img.url}
            alt="Service"
            style={{ 
              maxWidth: '100%', // Asegura que la imagen no sea más ancha que el contenedor
              maxHeight: '100%', // Asegura que la imagen no sea más alta que el contenedor
              width: 'auto',  // Mantiene la relación de aspecto de la imagen
              height: 'auto', // Mantiene la relación de aspecto de la imagen
              display: img.loaded ? 'block' : 'none'
            }}
            onLoad={() => setImages(prevImages => {
              const newImages = [...prevImages];
              newImages[index] = { ...newImages[index], loaded: true };
              return newImages;
            })}
          />
          {!img.loaded && (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
          )}
        </div>
        {img.isPreview ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-preview-${index}`}>{t('ServiceDetail.imageNotSaved')}</Tooltip>}
          >
            <div style={{
              position: 'absolute',
              top: 0,
              right: 0,
              cursor: 'pointer'
            }}>
              <MdVisibility
                style={{
                  fontSize: '20px',
                  color: 'gray'
                }}
              />
            </div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-delete-${index}`}>{t('ServiceDetail.Delete')}</Tooltip>}
          >
            <Button
              onClick={() => handleDeleteImage(img.id, img.file)}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                padding: '2px 5px',
                margin: '2px 2px',
                fontSize: '10px',
                color: '#fff',
                backgroundColor: 'red',
                border: 'none'
              }}
            >
              <MdDelete />
            </Button>
          </OverlayTrigger>
        )}
      </div>
    ));
  };


  // Limpieza de URLs de vista previa cuando el componente se desmonta o se actualiza
  useEffect(() => {
    return () => {
      images.forEach(image => {
        if (image.file) URL.revokeObjectURL(image.url);
      });
    };
  }, [images]);


  const handleDeleteImage = async (fileId) => {
    try {
      const response = await deleteServiceFile(data.id, fileId);
      if (response.status) {
        // Actualiza el estado inmediatamente después de la confirmación del servidor
        setImages(prevImages => prevImages.filter(image => image.id !== fileId));
      }
    } catch (error) {
      alert('Failed to delete the image: ' + error.message);
    }
  };


  const handleChange = (e) => {
    setError('');
    const { name, value } = e.target;
    setServiceInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  // For taxes
  const handleTaxChange = (id, field, value) => {
    const updatedTaxes = taxes.map(tax => {
      if (tax.id === id) {
        const updatedTax = { ...tax, [field]: value };
        
        // Calcular porcentaje si se actualiza el monto del impuesto
        if (field === 'tax_amount' && serviceInfo.unit_price > 0) {
          const percentage = (parseFloat(value) / parseFloat(serviceInfo.unit_price)) * 100;
          updatedTax.tax_percentage = percentage.toFixed(2);  // Redondea a dos decimales
        }
        
        // Calcular monto si se actualiza el porcentaje
        if (field === 'tax_percentage' && serviceInfo.unit_price > 0) {
          const amount = (parseFloat(value) / 100) * parseFloat(serviceInfo.unit_price);
          updatedTax.tax_amount = amount.toFixed(2);  // Redondea a dos decimales
        }

        return updatedTax;
      }
      return tax;
    });

    setTaxes(updatedTaxes);
  };


  const addTax = () => {
    const maxId = taxes.reduce((max, tax) => Math.max(max, tax.id), 0);
    const newTax = { id: maxId + 1, name: '', tax_amount: '', tax_percentage: '' };

    setTaxes([...taxes, newTax]);
  };


  const removeTax = (id) => {
    setTaxes(taxes.filter(tax => tax.id !== id));
  };


  // For schedules
  const handleAddSchedule = () => {
    const maxId = schedules.reduce((max, schedule) => Math.max(max, schedule.id), 0);
    const newSchedule = {
      id: maxId + 1,
      day: '',
      time_start: '',
      time_end: ''
    };
    setSchedules([...schedules, newSchedule]);
  };


  const removeSchedule = (id) => {
    setSchedules(schedules.filter(schedule => schedule.id !== id));
  };


  const handleScheduleChange = (id, field, value) => {
    setSchedules(schedules.map(schedule => {
      if (schedule.id === id) {
        return { ...schedule, [field]: value };
      }
      return schedule;
    }));
  };


  // Load data fron the server
  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetchCategories();
        setCategories(response.data.data.map(cat => ({
          id: cat.id,
          value: cat.name,
          label: t(cat.i18n_label),
          i18n: cat.i18n_label
        })));
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    
    const getRateTypes = async () => {
      try {
        const response = await fetchRateTypes();
        setRateTypes(response.data.data.map(cat => ({
          id: cat.id,
          value: cat.name,
          label: t(cat.i18n_label),
          i18n: cat.i18n_label
        })));
      } catch (error) {
        console.error('Error fetching rate_types:', error);
      }
    };

    getCategories();
    getRateTypes();

  }, []);


  // Form submission logic
  const handleSubmit = async () => {
    setIsSaving(true);

    const formData = new FormData();
    formData.append('name', serviceInfo.name);
    formData.append('description', serviceInfo.description);
    formData.append('category_id', serviceInfo.category_id);
    formData.append('unit_price', serviceInfo.unit_price);
    formData.append('rate_type_id', serviceInfo.rate_type_id);
    formData.append('taxable', serviceInfo.taxable);
    formData.append('extended_price', extendedPrice);

    // Añadir la latitud y longitud actual al formData
    formData.append('latitude', center.lat);
    formData.append('longitude', center.lng);
    formData.append('operation_area', radius); // Radio como área de operación

    // Añade archivos al FormData
    files.forEach(file => {
      formData.append('files[]', file);
    });

    // Añadir impuestos (taxes)
    taxes.forEach((tax, index) => {
        formData.append(`taxes[${index}][name]`, tax.name);
        formData.append(`taxes[${index}][tax_amount]`, tax.tax_amount);
        formData.append(`taxes[${index}][tax_percentage]`, tax.tax_percentage);
    });

    // Añadir horarios (schedules)
    schedules.forEach((schedule, index) => {
        formData.append(`schedules[${index}][day]`, schedule.day);
        formData.append(`schedules[${index}][time_start]`, schedule.time_start);
        formData.append(`schedules[${index}][time_end]`, schedule.time_end);
    });

    // Logic for creating or updating service based on presence of data.id
    try {
      const response = await saveService(formData, data);
      handleSaveSuccess(response);
    } catch (error) {
      setError(t('ServiceDetail.ErrorAddingService') + ': ' + (error.response ? error.response.data.message : error.message));
    } finally {
      setIsSaving(false);

    }
  };


  // Hide modal
  const onHideHandler = () => {
    setError('');
    onHide();
  };


  // Handle center changes
  const handleCenterChange = (newCenter) => {
    setCenter(newCenter);
  };


  // Handle radius change
  const handleRadiusChange = (newRadius) => {
    setRadius(newRadius);
  };


  // Area calculations in square kilometers
  useEffect(() => {
    const areaInSquareMeters = Math.PI * (radius * radius);
    const areaInSquareKilometers = areaInSquareMeters / 1e6;  // Convertir metros cuadrados a kilómetros cuadrados
    setArea(areaInSquareKilometers);
  }, [radius]);


  // // Calcular el impuesto y actualizarlo cada vez que cambia el precio
  // useEffect(() => {
  //   // Sólo recalcula si hay un precio unitario válido
  //   if (serviceInfo.unit_price > 0) {
  //     const recalculatedTaxes = taxes.map(tax => ({
  //       ...tax,
  //       tax_amount: ((parseFloat(tax.tax_percentage) / 100) * parseFloat(serviceInfo.unit_price)).toFixed(2),
  //     }));
  //     setTaxes(recalculatedTaxes);
  //   }
  // }, [serviceInfo.unit_price, taxes]);

  // Calcular el impuesto y actualizarlo cada vez que cambia el precio
  useEffect(() => {
    if (serviceInfo.unit_price > 0) {
      setTaxes(taxes.map(tax => ({
        ...tax,
        tax_amount: ((parseFloat(tax.tax_percentage) / 100) * parseFloat(serviceInfo.unit_price)).toFixed(2),
      })));
    }
  }, [serviceInfo.unit_price]);


  // // Calcular el precio total y actualizarlo cada vez que cambien el precio o los impuestos
  // useEffect(() => {
  //   const totalTaxes = taxes.reduce((acc, tax) => acc + parseFloat(tax.tax_amount || 0), 0);
  //   const totalExtendedPrice = parseFloat(serviceInfo.unit_price || 0) + totalTaxes;
  //   setExtendedPrice(totalExtendedPrice.toFixed(2)); // Formatea a dos decimales
  // }, [serviceInfo.unit_price, taxes]);

  // Calcular el precio total y actualizarlo cada vez que cambien el precio o los impuestos
  useEffect(() => {
    const totalTaxes = taxes.reduce((acc, tax) => acc + parseFloat(tax.tax_amount || 0), 0);
    const totalExtendedPrice = parseFloat(serviceInfo.unit_price || 0) + totalTaxes;
    setExtendedPrice(totalExtendedPrice.toFixed(2));
  }, [taxes, serviceInfo.unit_price]);


  // Redimensionamiento del body de la ventana
  useEffect(() => {
    function updateMaxHeight() {
      const headerHeight = 100;
      const footerHeight = 100;
      const windowHeight = window.innerHeight;
      const availableHeight = windowHeight - headerHeight - footerHeight;
      setMaxBodyHeight(`${availableHeight}px`);
    }

    updateMaxHeight();
    window.addEventListener('resize', updateMaxHeight);// Añade un listener para actualizaciones en el redimensionado

    return () => {
      window.removeEventListener('resize', updateMaxHeight); // Limpia el listener al desmontar el componente
    };
  }, []);


  // Save success handler
  const handleSaveSuccess = (response) => {
    onSave(response.data);
    setError('');
    onHide();
  };


  return (
    <Modal size="xl" show={show} onHide={onHideHandler} centered style={{ display: 'flex', flexDirection: 'column' }}>
      <Modal.Header closeButton>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Modal.Title style={{ flex: 1 }}>
            {title}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto', maxHeight: maxBodyHeight }}>

        {error && (
          <Alert variant="danger">
            {error}
          </Alert>
        )}

        <Row style={{ flex: 1 }}>
          <Col md={6} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
            <Form>
              <Container fluid className="mb-2">
                <Row>
                  <Col>
                    <CustomField 
                      name="name"
                      label={t('ServiceDetail.ServiceNameLabel')} 
                      placeholder={t('ServiceDetail.ServiceNameLabel')} 
                      editable={true}
                      onChange={handleChange}
                      value={serviceInfo.name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CustomTextarea 
                      name="description"
                      label={t('ServiceDetail.DescriptionLabel')} 
                      placeholder={t('ServiceDetail.DescriptionLabel')} 
                      editable={true}
                      onChange={handleChange}
                      value={serviceInfo.description}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CustomSelect
                      name="category_id"
                      label={t('ServiceDetail.CategoryLabel')} 
                      options={categories}
                      placeholder={t('ServiceDetail.CategoryLabel')} 
                      editable={true}
                      onChange={handleChange}
                      value={serviceInfo.category_id}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CustomField 
                      type="number"
                      name="unit_price"
                      label={t('ServiceDetail.PriceLabel')} 
                      placeholder={t('ServiceDetail.PriceLabel')}
                      editable={true}
                      onChange={handleChange}
                      value={serviceInfo.unit_price}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CustomSelect
                      name="rate_type_id"
                      label={t('ServiceDetail.RateTypeLabel')}
                      options={rateTypes}
                      placeholder={t('ServiceDetail.RateTypeLabel')}
                      editable={true}
                      onChange={handleChange}
                      value={serviceInfo.rate_type_id}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CustomSelect
                      name="taxable"
                      label={t('ServiceDetail.TaxableLabel')}
                      options={[
                        { id: '0', label: t('ServiceDetail.No') },
                        { id: '1', label: t('ServiceDetail.Yes') },
                      ]}
                      placeholder={t('ServiceDetail.TaxableLabel')}
                      editable={true}
                      onChange={handleChange}
                      value={serviceInfo.taxable.toString()}
                    />
                  </Col>
                </Row>

                {/* Mostrar la tabla de impuestos solo si taxable es '1' */}
                {serviceInfo.taxable === '1' && (
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-between align-items-center table-title">
                        <h5>{t('ServiceDetail.TaxSectionTitle')}</h5>

                        <Button className="btn-sm" onClick={addTax}>
                          <MdAdd size={20} />
                        </Button>
                      </div>
                      
                      <Table responsive bordered>
                        <thead>
                          <tr>
                            <th style={{fontSize:'0.9rem'}}>{t('ServiceDetail.TaxNameLabel')}</th>
                            <th style={{fontSize:'0.9rem'}}>{t('ServiceDetail.TaxAmountLabel')}</th>
                            <th style={{fontSize:'0.9rem'}}>{t('ServiceDetail.TaxPercentageLabel')}</th>
                            <th style={{fontSize:'0.9rem'}}>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {taxes.map((tax, index) => (
                            <tr key={tax.id}>
                              <td >
                                <CustomField
                                  type="text"
                                  name={`taxName-${tax.id}`}
                                  value={tax.name}
                                  editable={true}
                                  placeholder={t('ServiceDetail.TaxNameLabel')}
                                  onChange={(e) => handleTaxChange(tax.id, 'name', e.target.value)}
                                />
                              </td>
                              <td>
                                <CustomField
                                  type="number"
                                  name={`taxPercentage-${tax.id}`}
                                  value={tax.tax_percentage}
                                  editable={true}
                                  placeholder={t('ServiceDetail.TaxPercentageLabel')}
                                  onChange={(e) => handleTaxChange(tax.id, 'tax_percentage', e.target.value)}
                                />
                              </td>
                              <td>
                                <CustomField
                                  type="number"
                                  name={`taxAmount-${tax.id}`}
                                  value={tax.tax_amount}
                                  editable={true}
                                  placeholder={t('ServiceDetail.TaxAmountLabel')}
                                  onChange={(e) => handleTaxChange(tax.id, 'tax_amount', e.target.value)}
                                />
                              </td>
                              <td>
                                <Button className="btn-sm" variant="danger" onClick={() => removeTax(tax.id)}>
                                  <MdDelete size={20} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                )}

                {serviceInfo.taxable === '1' && (
                  <div className="text-end" style={{ marginBottom: '10px', fontSize: '1.2rem', fontWeight: 'bold' }}>
                    {t('ServiceDetail.TotalPrice')}: ${extendedPrice}
                  </div>
                )}

                <Row>
                  <Col>
                    
                    <div className="d-flex justify-content-between align-items-center table-title" >
                      <h5>{t('ServiceDetail.ScheduleTitle')}</h5>
                      
                      <Button className="btn-sm" onClick={handleAddSchedule}>
                        <MdAdd size={20} />
                      </Button>
                    </div>
                    
                    <Table responsive bordered>
                      <thead>
                        <tr>
                          <th style={{fontSize:'0.9rem'}}>{t('ServiceDetail.DayLabel')}</th>
                          <th style={{fontSize:'0.9rem'}}>{t('ServiceDetail.StartTimeLabel')}</th>
                          <th style={{fontSize:'0.9rem'}}>{t('ServiceDetail.EndTimeLabel')}</th>
                          <th style={{fontSize:'0.9rem'}}>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {schedules.map((schedule, index) => (
                          <tr key={schedule.id}>
                            <td>
                              <CustomSelect
                                name={`scheduleDay-${schedule.id}`}
                                options={[
                                    { value: '', label: t('ServiceDetail.select') },
                                    { value: 'monday', label: t('ServiceDetail.monday') },
                                    { value: 'tuesday', label: t('ServiceDetail.tuesday') },
                                    { value: 'wednesday', label: t('ServiceDetail.wednesday') },
                                    { value: 'thursday', label: t('ServiceDetail.thursday') },
                                    { value: 'friday', label: t('ServiceDetail.friday') },
                                    { value: 'saturday', label: t('ServiceDetail.saturday') },
                                    { value: 'sunday', label: t('ServiceDetail.sunday') }
                                ]}
                                value={schedule.day}
                                placeholder={t('ServiceDetail.DayLabel')}
                                editable={true}
                                onChange={(e) => handleScheduleChange(schedule.id, 'day', e.target.value)}
                              />
                            </td>
                            <td>
                              <CustomField
                                type="time"
                                name={`startTime-${schedule.id}`}
                                value={schedule.time_start}
                                editable={true}
                                onChange={(e) => handleScheduleChange(schedule.id, 'time_start', e.target.value)}
                              />
                            </td>
                            <td>
                              <CustomField
                                type="time"
                                name={`endTime-${schedule.id}`}
                                value={schedule.time_end}
                                editable={true}
                                onChange={(e) => handleScheduleChange(schedule.id, 'time_end', e.target.value)}
                              />
                            </td>
                            <td>
                              <Button variant="danger" className="btn-sm" onClick={() => removeSchedule(schedule.id)}>
                                <MdDelete size={20} />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                  </Col>
                </Row>
              </Container>
            </Form>
          </Col>

          <Col md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div className="image-upload-section" style={{ flex: 1, padding: '10px', minHeight: '250px', display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                <h4>{t('ServiceDetail.ImageSectionTitle')}</h4>
                {images.length > 0 && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip">{t('ServiceDetail.AddImageButton')}</Tooltip>}
                  >
                    <Button 
                      className="rounded-circle"
                      style={{ width: '35px', height: '35px', border: '0.5px solid lightgray', color: 'lightgray', background: 'transparent', transition: 'all 0.3s ease', padding: '0'}}
                      onMouseEnter={(e) => e.currentTarget.style.background = 'gray'}
                      onMouseLeave={(e) => e.currentTarget.style.background = 'transparent'}
                      onClick={handleAddImageClick}
                    >
                      <MdOutlineAddPhotoAlternate style={{ fontSize: '15px' }} />
                    </Button>
                  </OverlayTrigger>
                )}
              </div>

              <div
                className={images.length > 0 ? "d-flex flex-wrap" : "d-flex align-items-center justify-content-center w-100"}
                style={{flex: 1, minHeight: '100px' }}
              >
                {handleImageDisplay()}

                {images.length === 0 && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip">{t('ServiceDetail.AddImageButton')}</Tooltip>}
                  >
                    <Button 
                      className="btn rounded-circle p-3"
                      style={{
                        width: '70px',
                        height: '70px',
                        border: '0.5px solid lightgray',
                        color: 'lightgray',
                        background: 'transparent',
                        transition: 'all 0.3s ease'
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.background = 'gray';
                        e.currentTarget.style.color = 'white';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.background = 'transparent';
                        e.currentTarget.style.color = 'lightgray';
                      }}
                      onClick={handleAddImageClick}
                    >
                      <MdOutlineAddPhotoAlternate style={{ fontSize: '30px' }} />
                    </Button>
                  </OverlayTrigger>
                )}
              </div>

              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                multiple
                accept="image/*"
                style={{ display: 'none' }}
              />
            </div>

            {/* Fila reservada para el mapa */}
            <div className="map-section" style={{ height: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <h4 className="text-md-left text-center">{t('ServiceDetail.MapSectionTitle')}</h4>
              {/* El mapa se insertará aquí */}

              <div className="map-section" style={{width:'100%'}}>
                <MapPicker
                  center={center}
                  radius={radius}
                  onCenterChange={handleCenterChange}
                  onRadiusChange={handleRadiusChange}
                />
                <input type="range" min="100" max="10000" value={radius} onChange={e => handleRadiusChange(e.target.value)} />
                <small className="text-danger">{t('ServiceDetail.AreaHelpText')}: <b>{area.toFixed(3)} km²</b></small>
              </div>
            </div>
          </Col>

        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHideHandler}>{t('ServiceDetail.CancelButton')}</Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={isSaving}
        >
          {isSaving ? t('ServiceDetail.Saving') : t('ServiceDetail.SaveButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ServiceDetail;

