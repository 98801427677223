import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { updatePassword } from '../services/TaskiMapApiService';
import { tryLogout } from '../services/TaskiMapApiService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UpdatePasswordModal = ({ show, onHide }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    if (newPassword !== confirmPassword) {
      setError(t('UpdatePasswordModal.PasswordMismatch'));
      setLoading(false);
      return;
    }

    try {
      await updatePassword({
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword
      });

      // Mostrar mensaje de éxito
      onHide();
      alert(t('UpdatePasswordModal.SuccessMessage'));

      // Desloguear al usuario
      await tryLogout();
      navigate('/');

    } catch (err) {
      setError(err.response?.data?.message || t('UpdatePasswordModal.resetFailed'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('UpdatePasswordModal.Title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>{t('UpdatePasswordModal.CurrentPassword')}</Form.Label>
            <Form.Control
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
              placeholder={t('UpdatePasswordModal.CurrentPasswordPlaceholder')}
              autoComplete="off"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t('UpdatePasswordModal.NewPassword')}</Form.Label>
            <Form.Control
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              placeholder={t('UpdatePasswordModal.NewPasswordPlaceholder')}
              autoComplete="off"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t('UpdatePasswordModal.ConfirmPassword')}</Form.Label>
            <Form.Control
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              placeholder={t('UpdatePasswordModal.ConfirmPasswordPlaceholder')}
              autoComplete="off"
            />
          </Form.Group>

          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : t('UpdatePasswordModal.Submit')}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePasswordModal;