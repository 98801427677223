import React, { useState, useEffect } from 'react';

import { tryRegisterProfessional, fetchCountries } from '../services/TaskiMapApiService';
import { Button, Form, Col, Spinner, Alert } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { normalizeText } from '../utils/textUtils';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import '../styles/App.css';

function RegisterServiceProvider() {

  const { t, i18n } = useTranslation();
  
  const storedLocation = JSON.parse(localStorage.getItem('geoData'));
  const [acceptTerms, setAcceptTerms] = useState(false);
  const lng = i18n.language.split('-')[0];
  const [countryCode, setCountryCode] = useState('');
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  const [user, setUser] = useState({
    full_name: '',
    phone: '',
    email: '',
    password: '',
    password_confirmation: ''
  });

  // Manejar el cambio del número de teléfono
  const handlePhoneChange = (event) => {
    const { value } = event.target;
    setUser({ ...user, phone: `${countryCode}${value}` });
  };

  // Manejar cambios en otros campos
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  // Manejar envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await tryRegisterProfessional(user);
      localStorage.setItem('authToken', response.data.data.token);
      navigate(`/${lng}/dashboard`);
    } catch (err) {
      setError(err.response?.data?.message || t('Errors.registrationFailed'));
    } finally {
      setLoading(false);
    }
  };

  // Cargar lista de países y preseleccionar el país basado en la geolocalización
  useEffect(() => {
    const getCountries = async () => {
      try {
        const response = await fetchCountries();
        const countryList = response.data.data;
        setCountries(countryList);

        // Extraer el país del campo 'location' en storedLocation
        const geoCountry = storedLocation?.location.split(',').pop().trim().toLowerCase();
        const normalizedGeoCountry = normalizeText(geoCountry); // Normalizamos el nombre del país

        // Función para verificar la similitud entre cadenas
        const stringSimilarity = (str1, str2) => {
          const normalizedStr1 = normalizeText(str1);
          const normalizedStr2 = normalizeText(str2);
          
          let longer = normalizedStr1.length > normalizedStr2.length ? normalizedStr1 : normalizedStr2;
          let shorter = normalizedStr1.length > normalizedStr2.length ? normalizedStr2 : normalizedStr1;

          let commonLength = shorter.split('').filter(char => longer.includes(char)).length;

          return commonLength / longer.length;
        };

        // Función para verificar la coincidencia del país en varios campos
        const findCountryMatch = (country) => {
          const normalizedName = normalizeText(country.name);
          const normalizedDisplayName = normalizeText(country.display_name);
          const normalizedI18nLabel = normalizeText(country.i18n_label);

          // Calculamos la similitud con cada campo
          const nameSimilarity = stringSimilarity(normalizedGeoCountry, normalizedName);
          const displayNameSimilarity = stringSimilarity(normalizedGeoCountry, normalizedDisplayName);
          const i18nLabelSimilarity = stringSimilarity(normalizedGeoCountry, normalizedI18nLabel);

          // Consideramos una coincidencia si la similitud es mayor al 60%
          const threshold = 0.6;
          return (
            nameSimilarity > threshold ||
            displayNameSimilarity > threshold ||
            i18nLabelSimilarity > threshold
          );
        };

        // Buscar coincidencia de país usando el enfoque mejorado
        const foundCountry = countryList.find(findCountryMatch);

        // Solo actualizamos si el código de país cambia
        if (foundCountry && foundCountry.phone_code !== countryCode) {
          setCountryCode(foundCountry.phone_code);
        }
      } catch (err) {
        console.error('Error fetching countries:', err);
      }
    };

    if (storedLocation) {
      getCountries();
    }
  }, []);

  return (
    <div className="vh-100 d-flex flex-column justify-content-between">

      <SEO
        title={t('SEO.RegisterServiceProvider.PageTitle')}
        description={t('SEO.RegisterServiceProvider.PageDescription')}
        keywords={t('SEO.RegisterServiceProvider.Keywords')}
        url={`${window.location.origin}/${lng}`}
        image={`${window.location.origin}/images/taskimap.jpg`}
      />
      
      <Header 
        showTitle={true} 
        showRegisterButtom={false}
        loadingLocation={false}
        currentLocation={storedLocation?.location}
      />

      <div className="register-container">
        <Col md={6} className="mx-auto">
          <Form onSubmit={handleSubmit} className="register-form shadow-lg p-4 rounded">
            <h1 className="h3 mb-3 text-center register-title">{t('RegisterServiceProvider.Title')}</h1>

            {error && <Alert variant="danger">{error}</Alert>}

            <Form.Group className="mb-3">
              <Form.Label className="form-label">{t('RegisterServiceProvider.FullName')}</Form.Label> 
              <Form.Control 
                type="text" 
                name="full_name" 
                required 
                value={user.full_name} 
                onChange={handleChange} 
                placeholder={t('RegisterServiceProvider.FullNamePlaceholder')} 
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="form-label">{t('RegisterServiceProvider.Phone')}</Form.Label> 

              <div className="d-flex">
                {/* Selección de código del país */}
                <Form.Select
                  aria-label={t('RegisterServiceProvider.SelectCountryCode')}
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  className="me-2"
                  required
                  style={{ width: '35%' }}
                >
                  <option value="">{t('RegisterServiceProvider.SelectCountryCode')}</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.phone_code}>
                      {t(country.i18n_label)} ({country.phone_code})
                    </option>
                  ))}
                </Form.Select>

                {/* Campo para ingresar el número de teléfono */}
                <Form.Control
                  type="tel"
                  name="phone"
                  required
                  value={user.phone.replace(countryCode, '')}
                  onChange={handlePhoneChange}
                  placeholder={t('RegisterServiceProvider.PhonePlaceholder')}
                  style={{ width: '65%' }}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="form-label">{t('RegisterServiceProvider.Email')}</Form.Label> 
              <Form.Control 
                type="email" 
                name="email" 
                required 
                value={user.email} 
                onChange={handleChange} 
                placeholder={t('RegisterServiceProvider.EmailPlaceholder')} 
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t('RegisterServiceProvider.Password')}</Form.Label>
              <Form.Control
                type="password"
                name="password"
                required
                value={user.password}
                onChange={handleChange}
                placeholder={t('RegisterServiceProvider.PasswordPlaceholder')}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>{t('RegisterServiceProvider.ConfirmPassword')}</Form.Label>
              <Form.Control
                type="password"
                name="password_confirmation"
                required
                value={user.password_confirmation}
                onChange={handleChange}
                placeholder={t('RegisterServiceProvider.ConfirmPasswordPlaceholder')}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check 
                type="checkbox"
                id="acceptTerms"
                label={
                  <span>
                    <Trans i18nKey="RegisterServiceProvider.AcceptTermsText">
                      Acepto los <a href={`/${lng}/terms-and-conditions`}>términos y condiciones</a> y la <a href={`/${lng}/privacy-policy`}>política de privacidad</a>.
                    </Trans>
                  </span>
                }
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
                required
              />
            </Form.Group>

            <div className="d-flex justify-content-between mt-4">
              <Button variant="link" onClick={() => navigate('/')}>
                {t('RegisterServiceProvider.Cancel')}
              </Button>

              <Button variant="primary" type="submit" disabled={loading || !acceptTerms} className="btn-lg register-button">
                {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : t('RegisterServiceProvider.Register')}
              </Button>
            </div>
          </Form>
        </Col>
      </div>

      <Footer />

    </div>
  );
}

export default RegisterServiceProvider;