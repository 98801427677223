import React, { useState, useEffect } from 'react';

import { ListGroup, Image, Badge, Card, Row, Col } from 'react-bootstrap';
import { fetchRatings } from '../services/TaskiMapApiService';
import LoadingScreen from '../components/LoadingScreen';
import ModalMessage from '../components/ModalMessage';
import { useUser } from '../contexts/UserContext';
import StarRating from '../components/StarRating';
import { useTranslation } from 'react-i18next';
import Sidebar from '../components/Sidebar';
import SEO from '../components/SEO';
import '../styles/App.css';

function Reviews() {

  const { t, i18n } = useTranslation();

  const [stats, setStats] = useState({ given: {}, received: {} });
  const [userValidated, setUserValidated] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalType, setModalType] = useState('');
  const { user, validateSession } = useUser();
  const [reviews, setReviews] = useState([]);
  const lng = i18n.language.split('-')[0];

  const handleCloseModal = () => setShowModal(false);

  const fetchReviews = async () => {
    if (!user) return;
    setIsLoading(true);
    try {
      const response = await fetchRatings(user.id);
      setReviews(response.data.data.ratings);
      setStats(response.data.data.stats);
      setIsLoading(false);
    } catch (error) {
      setModalTitle('Error');
      setModalMessage(error.response?.data?.message || 'An error occurred while fetching reviews.');
      setModalType('error');
      setShowModal(true);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (userValidated) {
      fetchReviews();
    }
  }, [userValidated]);


  useEffect(() => {
    validateSession().then(() => setUserValidated(true));
  }, []);


  if (isLoading) {
    return <LoadingScreen />;
  }


  return (
    <div className="d-flex">

      <SEO
        title={t('SEO.Reviews.PageTitle')}
        description={t('SEO.Reviews.PageDescription')}
        keywords={t('SEO.Reviews.Keywords')}
        url={`${window.location.origin}/${lng}`}
        image={`${window.location.origin}/images/taskimap.jpg`}
      />

      <Sidebar />

      <div className="m-3 flex-grow-1">
        <h1>{t('Reviews.MyReviews')}</h1>
        <Row>
          <Col sm={6}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>{t('Reviews.Givens')}</Card.Title>
                <Card.Text>
                  <div>
                    <StarRating value={stats.given.average || 0} size={20} editable={false} />
                  </div>
                </Card.Text>
                <Card.Text>
                  <strong>{t('Reviews.Total')}:</strong> {stats.given.count || 0}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>{t('Reviews.Receiveds')}</Card.Title>
                <Card.Text>
                  <div>
                    <StarRating value={stats.received.average || 0} size={20} editable={false} />
                  </div>
                </Card.Text>
                <Card.Text>
                  <strong>{t('Reviews.Total')}:</strong> {stats.received.count || 0}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="reviews-list" style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <ListGroup className="shadow-sm">
            {reviews.map((review) => (
              <ListGroup.Item key={review.id} className="d-flex align-items-center justify-content-between p-3">
                <div className="d-flex align-items-start">
                  <Image
                    src={review.rater.data_user.img_avatar || 'https://via.placeholder.com/150'}
                    roundedCircle
                    style={{ width: '50px', height: '50px', marginRight: '15px', border: '0.5px solid #ccc' }}
                  />
                  <div>
                    <h5>{review.rater.data_user.first_name} {review.rater.data_user.last_name}</h5>
                    <StarRating value={review.rating} size={20} editable={false} />
                    <p>{review.review}</p>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-between" style={{ height: '100px' }}>
                  <Badge bg={review.is_provider_rating ? "secondary" : "success"}>
                    {review.is_provider_rating ? t('Reviews.Given') : t('Reviews.Received')}
                  </Badge>
                  <div className="text-muted small">
                    {new Date(review.created_at).toLocaleString('en-US', {
                      month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit'
                    })}
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>

        <ModalMessage
          show={showModal}
          onHide={handleCloseModal}
          title={modalTitle}
          message={modalMessage}
          type={modalType}
        />

      </div>
    </div>
  );
}

export default Reviews;

