import React from 'react';

import { FaChartBar, FaStar, FaTools, FaEuroSign } from 'react-icons/fa'
import { Card, Row, Col, ListGroup } from 'react-bootstrap';
import LoadingScreen from '../components/LoadingScreen';
import { useTranslation, Trans } from 'react-i18next';
import { Doughnut, Line } from 'react-chartjs-2';
import Sidebar from '../components/Sidebar';
import useAuth from '../hooks/useAuth';
import SEO from '../components/SEO';
import '../styles/App.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard = () => {

  const { t, i18n } = useTranslation();
  const lng = i18n.language.split('-')[0];
  const { isLoading } = useAuth();

  // Datos para los gráficos
  const financialData = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
    datasets: [{
      label: 'Presupuestado (€)',
      data: [600, 800, 1100, 1400, 1700, 2000],
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1
    }, {
      label: 'Facturado (€)',
      data: [500, 700, 1000, 1200, 1500, 1800],
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1
    }]
  };

  const proposalPieData = {
    labels: ['Creados', 'Aprobados', 'Rechazados'],
    datasets: [{
      data: [10, 7, 3],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
    }]
  };

  const invoicePieData = {
    labels: ['Pendientes', 'Pagadas', 'Vencidas'],
    datasets: [{
      data: [2, 5, 1],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
    }]
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div className="d-flex">

      <SEO
        title={t('SEO.Dashboard.PageTitle')}
        description={t('SEO.Dashboard.PageDescription')}
        keywords={t('SEO.Dashboard.Keywords')}
        url={`${window.location.origin}/${lng}`}
        image={`${window.location.origin}/images/taskimap.jpg`}
      />

      <Sidebar />
      
      <div className="m-3 flex-grow-1">
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <Row className="mb-4">
              <Col md={3}>
                <Card className="card-service-height">
                  <Card.Body>
                    <FaChartBar className="icon-big text-primary" />
                    <Card.Title className="text-center">Servicios Ofrecidos</Card.Title>
                    <Card.Text>5</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-service-height">
                  <Card.Body>
                    <FaStar className="icon-big text-warning" />
                    <Card.Title className="text-center">Valoración Media</Card.Title>
                    <Card.Text>4.5/5</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-service-height">
                  <Card.Body>
                    <FaTools className="icon-big text-success" />
                    <Card.Title className="text-center">Servicios Totales Prestados</Card.Title>
                    <Card.Text>50</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-service-height">
                  <Card.Body>
                    <FaEuroSign className="icon-big text-danger" />
                    <Card.Title className="text-center">Ingresos Totales (€)</Card.Title>
                    <Card.Text>3500</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Card>
                  <Card.Header>Tareas Pendientes</Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Responder mensaje de Pedro</ListGroup.Item>
                    <ListGroup.Item>Confirmar cita con María</ListGroup.Item>
                    <ListGroup.Item>Actualizar información del perfil</ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <Card.Header>Reseñas Recientes</Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Juana López: Excelente servicio, muy profesional. <span className="badge bg-warning text-dark">4.5/5</span></ListGroup.Item>
                    <ListGroup.Item>Carlos Martínez: Rápido y eficiente. <span className="badge bg-warning text-dark">5/5</span></ListGroup.Item>
                    <ListGroup.Item>Ana García: Muy buen trabajo. <span className="badge bg-warning text-dark">4/5</span></ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
            </Row>

            <Row className="my-4">
              <Col md={3}>
                <Card>
                  <Card.Header>Estados de Presupuestos</Card.Header>
                  <Card.Body>
                    <Doughnut data={proposalPieData} />
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <Card.Header>Estados de Facturas</Card.Header>
                  <Card.Body>
                    <Doughnut data={invoicePieData} />
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <Card.Header>Resumen Financiero</Card.Header>
                  <Card.Body>
                    <Line data={financialData} options={options} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;

