// LoginForm.js

import React, { useState, useEffect } from 'react';
import { verifyTwoFactor } from '../services/TaskiMapApiService';
import { Form, Alert, Button, Spinner } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import TwoFactorAuthModal from './TwoFactorAuthModal';
import { useUser } from '../contexts/UserContext';

const LoginForm = ({ onClose, callBackAction }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.split('-')[0];
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { login, setUser } = useUser();
  const [showTwoFactorAuthModal, setShowTwoFactorAuthModal] = useState(false);
  const [tempToken, setTempToken] = useState(null);
  const [tempUser, setTempUser] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const loginResult = await login({ email, password });
      setLoading(false);
      if (loginResult.success) {
        // Inicio de sesión exitoso sin 2FA
        if (callBackAction && typeof callBackAction === 'function') {
          callBackAction();
        }
        onClose();
      } else if (loginResult.twoFactorRequired) {
        // Se requiere 2FA
        setTempToken(loginResult.tempToken);
        setTempUser(loginResult.tempUser);
        setShowTwoFactorAuthModal(true);
      }
    } catch (err) {
      console.log({ err });
      setError(err.response?.data?.message || t('Errors.loginFailed'));
      setLoading(false);
    }
  };

  const handleVerifyTwoFactor = async (code, method) => {
    setLoading(true);
    setError('');
    try {
      // Llamamos a verifyTwoFactor para verificar el código
      // Necesitamos pasar el tempToken en los headers de autorización
      const response = await verifyTwoFactor(
        { code, two_factor_type: method, purpose: 'login' },
        tempToken // Pasamos el tempToken
      );
      // Guardamos el token y los datos del usuario
      setUser(tempUser);
      localStorage.setItem('authToken', tempToken);
      setLoading(false);
      setShowTwoFactorAuthModal(false);
      if (callBackAction && typeof callBackAction === 'function') {
        callBackAction();
      }
      onClose();
    } catch (err) {
      console.log({ err });
      setError(err.response?.data?.message || t('Errors.invalidTwoFactorCode'));
      setLoading(false);
    }
  };

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleLogin}>
        {/* ... Campos del formulario ... */}
        <Form.Group className="mb-3">
          <Form.Label>{t('LoginButton.Email')}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t('LoginButton.EmailPlaceholder')}
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{t('LoginButton.Password')}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t('LoginButton.PasswordPlaceholder')}
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        {/* ... Otros campos y botones ... */}
        <div className="d-flex justify-content-end">
          <Button variant="secondary" onClick={onClose}>
            {t('LoginButton.Cancel')}
          </Button>
          <Button type="submit" variant="primary" disabled={loading} className="ms-2">
            {loading ? <Spinner as="span" animation="border" size="sm" /> : t('LoginButton.Login')}
          </Button>
        </div>
      </Form>

      {/* Modal para el 2FA */}
      <TwoFactorAuthModal
        show={showTwoFactorAuthModal}
        onHide={() => setShowTwoFactorAuthModal(false)}
        onConfirm={handleVerifyTwoFactor}
        description={t('LoginButton.LoginDescription')}
        purpose="login"
        tempToken={tempToken}
        error={error}
        setError={setError}
      />
    </>
  );
};

export default LoginForm;